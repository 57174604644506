import React, { Component } from 'react'
import { createBrowserHistory } from 'history';
import _ from 'lodash'
import logo from './aito-logo.svg'
import axios from 'axios'
import Slider from 'rc-slider'
import aitoBeer from './aito_ale_small.png'
import './App.css'
import 'rc-slider/assets/index.css'

const history = createBrowserHistory();

// This was decided to leave out of the demo
function getRecommendation(state) {
  return axios.post(`${process.env.API_BASE_URL}/api/v1/_recommend`, {
    from: 'ratings',
    recommend: 'beer',
    select: ['id', '$why', '$p'],
    goal: { 'liked': true }
  }, {
    headers: {
      'x-api-key': process.env.API_KEY,
    },
  })
}

function randomComment() {
  return _.sample([
    { author: 'An', text: 'I thought nothing could beat a Belgian beer – I was wrong. ❤️ Aito beer' },
    { author: 'Tri', text: 'Serving it warm really lets the flavours come together' },
    { author: 'Kai', text: 'Normally I drink water after a bike ride, but from now on it’s Aito beer' },
    { author: 'Antti', text: 'This is a dramatical improvement from anything I ever tasted before' },
    { author: 'Vesku', text: 'The taste is even better the next day' },
    { author: 'Christoffer', text: 'The aroma, the taste, the colour, it’s all a perfect harmony.' },
    { author: 'Olli', text: 'Helps my studies, helps my work, helps my hangover. Perfect, just perfect.' },
    { author: 'Kimmo', text: 'Just kova' },
    { author: 'Tommi', text: 'I don\'t even like beer, but it\'s free!' },
  ])
}

const marks = {
  0: {
    style: {
      color: 'white',
      left: '8%',
    },
    label: <strong>Not at all</strong>,
  },

  100: {
    style: {
      color: 'white',
      left: '95%'
    },
    label: <strong>Very</strong>,
  },
};

function getViewName(pathname) {
  const name = _.trimStart(_.trimEnd(pathname, '/'), '/')
  if (name.length < 1) {
    return 'start'
  }

  return name
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      view: getViewName(window.location.pathname),
      beerSize: 'normal',
      beerX: 'option1',
    }
  }

  componentDidMount() {
    // Listen for changes to the current location.
    history.listen((location, action) => {
      window.scrollTo(0, 0);
      const viewName = getViewName(location.pathname)
      this.setState({
        view: viewName,
      })
    });
  }

  onStart = () => {
    this.setView('preferences')
  }

  render() {
    return (
      <div className={`App App-${this.state.view}-page`}>
        {this.renderContent()}
      </div>
    )
  }

  renderContent() {
    switch (this.state.view) {
      case 'make':
        return this.renderMake()
      case 'recommendation':
        return this.renderRecommendation()
      case 'preferences':
        return this.renderPreferences()
      case 'start':
        return this.renderStartPage()
      default:
        return this.renderStartPage()
    }
  }

  renderStartPage() {
    return <React.Fragment>
      <h1>Aito Unchilld</h1>
      <h3>Discover your favorite beer</h3>
      <p>* results may be biased</p>
      <div className="aito-powered">
        <h4>powered by</h4>
        <img className="aito-logo" src={logo} alt="Aito" />
      </div>

      <button onClick={() => this.setView('preferences')}>Start</button>
    </React.Fragment>
  }

  onClickRecommend = () => {
    if (this.state.loading) {
      return
    }

    this.setState({ loading: true })
    getRecommendation(this.state)
      .then((result) => {
        this.setState({
          result,
          loading: false,
          view: 'recommendation',
        })
      })
      .catch(err => {
        this.setState({
          loading: false,
        }, () => alert('Failed to query Aito.ai, but we recommend Aito beer.'))
      })
  }

  renderPreferences() {
    return <React.Fragment>
      <h2>Tell us your preferences</h2>

      <div className="form-container">
        <label htmlFor="beer-lightness">What's your preferred beer size?</label>
        <div className="pure-css-select-style theme-default">
          <select id="beer-lightness" value={this.state.beerSize} onChange={(e) => this.setState({ beerSize: e.target.value })}>
            <option value="small">Small</option>
            <option value="normal">Normal</option>
            <option value="large">Large</option>
            <option value="x-large">Extra large</option>
          </select>
        </div>

        <label className="curly-label">How curly is your hair?</label>
        <div className="slider-container">
          <Slider min={0} marks={marks} step={10} defaultValue={50} />
        </div>

        <label htmlFor="beer-x">What's your favorite season?</label>
        <div className="pure-css-select-style theme-default">
          <select id="beer-x" value={this.state.beerX} onChange={(e) => this.setState({ beerX: e.target.value })}>
            <option value="spring">Spring</option>
            <option value="summer">Summer</option>
            <option value="autumn">Autumn</option>
            <option value="winter">Winter</option>
          </select>
        </div>
      </div>

      <button onClick={() => this.setView('recommendation')}>{this.state.loading ? 'Finding a beer..' : 'Recommend a beer'}</button>
    </React.Fragment>
  }

  renderRecommendation() {
    const comment = randomComment()
    return <React.Fragment>
      <h2>We recommend you</h2>
      <h3>Aito Beer</h3>
      <img className="aito-beer" src={aitoBeer} alt="Aito" />
      <h4>Best served at room temperature</h4>

      <p>“{comment.text}”</p>
      <p className="author">- {comment.author}</p>

      <button onClick={() => this.setView('make')}>Make your own</button>
    </React.Fragment>
  }

  renderMake() {
    return <React.Fragment>
      <h2>How to create your own Aito Beer</h2>
      <ol>
        <li>Take a beer</li>
        <li>Go to Aito Startup Garage</li>
        <li>Put an Aito sticker on it</li>
        <li>Enjoy!</li>
      </ol>

      <button onClick={this.restart}>Back to start</button>
    </React.Fragment>
  }

  restart() {
    window.location = '/'
  }

  setView(name) {
    history.push(`/${name}`)
  }
}

export default App
